<template>
	<div v-if="enabled" v-show="show" id="consoleOverlay">
		<input type="button" @click="close" value="close for 10 seconds">
		<div id="consoleOverlayLogs" ref="logEl"></div>
	</div>
</template>


<script setup lang="ts">
const logEl = ref({} as HTMLElement)
const enabled = new URLSearchParams(window.location.search).get("consoleOverlay")
const show = ref(false)

if (enabled) {
	show.value = true

	const log = console.log;
	const info = console.info;
	const warn = console.warn;
	const error = console.error;

	console.log = function (msg) { transfer(msg); log(msg) }
	console.info = function (msg) { transfer(msg); info(msg) }
	console.warn = function (msg) { transfer(msg); warn(msg) }
	console.error = function (msg) { transfer(msg); error(msg) }

	function transfer(message: any) {
		if (typeof message == 'object') {
			logEl.value.innerHTML += (JSON && JSON.stringify ? JSON.stringify(message) : message) + '<br />';
		} else {
			logEl.value.innerHTML += message + '<br />';
		}
	}
}

function close() {
	show.value = false
	setTimeout(() => {
		show.value = true
	}, 10 * 1000)
}
</script>

<style scoped lang="scss">
#consoleOverlay {
	position: fixed;
	z-index: 99999;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100vw;
	height: 100vh;
	padding: 1%;
	opacity: 0.6;

	#consoleOverlayLogs {
		width: 100%;
		height: 100%;
		background-color: black;
		color: white;
		overflow-y: scroll;
	}
}
</style>
